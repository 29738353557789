import React from "react";

const Password = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.7362 6.26345C11.3847 5.91206 10.8148 5.9122 10.4635 6.26375C10.1121 6.61531 10.1122 7.18516 10.4638 7.53655L11.3274 8.3998L10.4636 9.26361C10.1121 9.61508 10.1121 10.1849 10.4636 10.5364C10.8151 10.8879 11.3849 10.8879 11.7364 10.5364L12.6005 9.67229L13.4638 10.5351C13.8153 10.8865 14.3852 10.8864 14.7365 10.5348C15.0879 10.1833 15.0878 9.61343 14.7362 9.26204L13.8733 8.3995L14.7364 7.5364C15.0879 7.18492 15.0879 6.61508 14.7364 6.2636C14.3849 5.91213 13.8151 5.91213 13.4636 6.26361L12.6002 7.127L11.7362 6.26345ZM3.86345 6.26375C4.21484 5.9122 4.78469 5.91206 5.13625 6.26345L6.0002 7.127L6.8636 6.26361C7.21507 5.91213 7.78492 5.91213 8.13639 6.2636C8.48786 6.61508 8.48786 7.18492 8.13639 7.5364L7.27329 8.3995L8.13625 9.26204C8.4878 9.61343 8.48794 10.1833 8.13655 10.5348C7.78516 10.8864 7.21531 10.8865 6.86375 10.5351L6.0005 9.67229L5.1364 10.5364C4.78492 10.8879 4.21508 10.8879 3.8636 10.5364C3.51213 10.1849 3.51213 9.61508 3.8636 9.26361L4.72741 8.3998L3.86375 7.53655C3.5122 7.18516 3.51206 6.61531 3.86345 6.26375ZM17.4 10.2C16.9029 10.2 16.5 10.6029 16.5 11.1C16.5 11.5971 16.9029 12 17.4 12H19.5C19.9971 12 20.4 11.5971 20.4 11.1C20.4 10.6029 19.9971 10.2 19.5 10.2H17.4ZM3.89907 0C1.7448 0 0 1.74645 0 3.9V12.9C0 15.0539 1.74609 16.8 3.9 16.8H20.1C22.2539 16.8 24 15.0539 24 12.9V3.9C24 1.74645 22.2552 0 20.1009 0H3.89907ZM1.8 3.9C1.8 2.73984 2.73964 1.8 3.89907 1.8H20.1009C21.2604 1.8 22.2 2.73984 22.2 3.9V12.9C22.2 14.0598 21.2598 15 20.1 15H3.9C2.7402 15 1.8 14.0598 1.8 12.9V3.9Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default Password;
