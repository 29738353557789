import React from "react";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";
import { ColumnWeb } from "./Column.styles.web.js";

const Column = ({ children, size, align, margin, ...props }) => {
  const theme = getTheme();

  return (
    <ColumnWeb
      theme={theme}
      size={size}
      contentAlign={align}
      margin={margin}
      {...props}>
      {children}
    </ColumnWeb>
  );
};

Column.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOf(["x1", "x2", "x3", "x4", "x5", "x6"]),
  align: PropTypes.oneOf(["Start", "Center", "End"]),
  margin: PropTypes.oneOf(["sm-r", "sm-l", "sm-x", "md-r", "md-l", "md-x"]),
};

export default Column;
