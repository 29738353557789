import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ContainerWeb } from "./FilterBlock.styles.web.js";
import FilterCategory from "../FilterCategory/FilterCategory.component.react";
import FilterOption from "../FilterOption/FilterOption.component.react";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const FilterBlock = ({ title, options, value, onChange, style, ...props }) => {
  const theme = getTheme();
  const [selectedValue, setSelectedValue] = useState(value || options[0].value);

  const onChangeHandle = (newValue) => {
    setSelectedValue(newValue);
    if (onChange && typeof onChange === "function") onChange(value);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <ContainerWeb style={style} theme={theme} {...props}>
      <FilterCategory text={title} />
      {options.map((option) => (
        <FilterOption
          text={option.text}
          selected={option.value === selectedValue}
          onChange={() => onChangeHandle(option.value)}
          key={option.value}
          value={option.value}
        />
      ))}
    </ContainerWeb>
  );
};

FilterBlock.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  style: PropTypes.object,
};

export default FilterBlock;
