import styled, { css } from "styled-components";
import { default as styledWeb } from "styled-components";
import designTokens from "../../configs/design-tokens";

const styles = (theme) => css`
  padding-vertical: ${designTokens[theme].sizes.buttonPaddingVerticalMedium};
  border-radius: 0;
  align-self: flex-start;
  background-color: ${designTokens[theme].colors.neutral.n100};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${designTokens[theme].sizes.borderRadiusMedium};
  ${(props) =>
    props.big &&
    props.big === true &&
    css`
      padding-vertical: ${designTokens[theme].sizes.buttonPaddingVertical};
      border-radius: ${designTokens[theme].sizes.borderRadius};
    `}
  ${(props) =>
    props.selected &&
    props.selected === true &&
    css`
      background-color: ${designTokens[theme].colors.neutral.n900};
    `}
  ${(props) =>
    props.dark &&
    props.dark === true &&
    css`
      background-color: ${designTokens[theme].colors.secondary.s800};
    `}
  ${(props) =>
    props.dark &&
    props.dark === true &&
    props.selected &&
    props.selected === true &&
    css`
      background-color: ${designTokens[theme].colors.neutral.n50};
    `}
`;

const stylesText = (theme) => css`
  color: ${designTokens[theme].colors.neutral.n900};
  font-size: 16px;
  line-height: 22px;
  font-family: "Manrope";
  margin-horizontal: ${designTokens[theme].sizes.buttonPaddingHorizontalMedium};
  ${(props) =>
    props.big &&
    props.big === true &&
    css`
      font-size: 18px;
      margin-horizontal: ${designTokens[theme].sizes.buttonPaddingHorizontal};
    `}
  ${(props) =>
    props.selected &&
    props.selected === true &&
    css`
      color: ${designTokens[theme].colors.neutral.n0};
    `}
  ${(props) =>
    props.dark &&
    props.dark === true &&
    css`
      color: ${designTokens[theme].colors.neutral.n50};
    `}
  ${(props) =>
    props.dark &&
    props.dark === true &&
    props.selected &&
    props.selected === true &&
    css`
      color: ${designTokens[theme].colors.neutral.n900};
    `}
`;

export const ButtonWeb = styledWeb.div`
  ${styles("contbank")}
`;

export const ButtonTextWeb = styledWeb.div`
  ${stylesText("contbank")}
`;
