import React from "react";
import {
  IconBadgeWeb,
  CardWeb,
  ColumnWeb,
  TextWeb,
} from "./IconTextBadge.styles.web.js";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon.component.react";
import DesignTokens from "../../configs/design-tokens";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const IconTextBadge = ({
  showIcon,
  title,
  tagline,
  colorscheme,
  style,
  ...props
}) => {
  const theme = getTheme();
  const isDark = colorscheme === "Dark";

  return (
    <CardWeb theme={theme} dark={isDark} style={style} {...props}>
      {showIcon && (
        <IconBadgeWeb
          theme={theme}
          dark={isDark}
          medium={!tagline}
          style={style}>
          <Icon
            icon={showIcon}
            theme={theme}
            color={isDark && DesignTokens["contbank"].colors.neutral.n0}
          />
        </IconBadgeWeb>
      )}
      <ColumnWeb>
        {title && (
          <TextWeb type="H5" dark={isDark} theme={theme}>
            {title}
          </TextWeb>
        )}
        {tagline && (
          <TextWeb type="Paragraph Small" dark={isDark} theme={theme}>
            {tagline}
          </TextWeb>
        )}
      </ColumnWeb>
    </CardWeb>
  );
};

IconTextBadge.propTypes = {
  showIcon: PropTypes.string,
  title: PropTypes.string,
  tagline: PropTypes.string,
  colorscheme: PropTypes.oneOf(["Default", "Dark"]),
  style: PropTypes.object,
};

export default IconTextBadge;
