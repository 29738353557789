import React from "react";

const Report = (props) => {
  const { color } = props;
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.74979 9.49948C3.3356 9.49948 2.99984 9.83525 2.99984 10.2494C2.99984 10.6636 3.3356 10.9994 3.74979 10.9994C4.16398 10.9994 4.49975 10.6636 4.49975 10.2494C4.49975 9.83525 4.16398 9.49948 3.74979 9.49948ZM2.99984 13.2493C2.99984 12.8351 3.3356 12.4993 3.74979 12.4993C4.16398 12.4993 4.49975 12.8351 4.49975 13.2493C4.49975 13.6635 4.16398 13.9992 3.74979 13.9992C3.3356 13.9992 2.99984 13.6635 2.99984 13.2493ZM5.99967 10.2494C5.99967 9.83525 6.33544 9.49948 6.74963 9.49948H9.80321C10.2174 9.49948 10.5532 9.83525 10.5532 10.2494C10.5532 10.6636 10.2174 10.9994 9.80321 10.9994H6.74963C6.33544 10.9994 5.99967 10.6636 5.99967 10.2494ZM6.74963 12.4993C6.33544 12.4993 5.99967 12.8351 5.99967 13.2493C5.99967 13.6635 6.33544 13.9992 6.74963 13.9992H9.80321C10.2174 13.9992 10.5532 13.6635 10.5532 13.2493C10.5532 12.8351 10.2174 12.4993 9.80321 12.4993H6.74963ZM2.24988 0C1.0073 0 0 1.0073 0 2.24988V15.2492C0 16.4917 1.0073 17.499 2.24988 17.499H11.2494C12.492 17.499 13.4993 16.4917 13.4993 15.2492V6.86847C13.4993 6.27177 13.2622 5.6995 12.8403 5.27757L8.22169 0.658974C7.79976 0.23704 7.22749 0 6.63079 0H2.24988ZM1.49992 2.24988C1.49992 1.83569 1.83569 1.49992 2.24988 1.49992H6.50355V4.74727C6.50355 5.98984 7.51085 6.99714 8.75343 6.99714H11.9993V15.2492C11.9993 15.6634 11.6636 15.9991 11.2494 15.9991H2.24988C1.83569 15.9991 1.49992 15.6634 1.49992 15.2492V2.24988ZM8.00347 4.74727V2.56195L10.9387 5.49723H8.75343C8.33924 5.49723 8.00347 5.16146 8.00347 4.74727ZM4.75062 19.9989C3.77101 19.9989 2.93762 19.3729 2.62876 18.499H11.2503C13.0451 18.499 14.5001 17.044 14.5001 15.2492V6.93651L15.341 7.77745C15.763 8.19939 16 8.77165 16 9.36836V15.2492C16 17.8724 13.8735 19.9989 11.2503 19.9989H4.75062Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default Report;
