import { default as styledWeb } from "styled-components";
import { styles, stylesText } from "./Switch.styles.base";

export const ButtonWeb = styledWeb.div`
  ${(props) => styles(props)}
`;

export const ButtonTextWeb = styledWeb.div`
  ${(props) => stylesText(props)}
`;
