import { css } from "styled-components";
import designTokens from "./design-tokens";

export const marginStylesTop = (props) => css`
  ${(props) =>
    props.mtNone === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.mtXS === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.mtS === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.mtM === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.mtL === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.mtXL === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.mtXXL === true &&
    css`
      margintop: ${designTokens[props.theme].spaces.spaceXXL};
    `}}
`;

export const marginStylesBottom = (props) => css`
  ${(props) =>
    props.mbNone === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.mbXS === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.mbS === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.mbM === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.mbL === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.mbXL === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.mbXXL === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const marginStylesVertical = (props) => css`
  ${(props) =>
    props.myNone === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.noSpace};
      margintop: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.myXS === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceXS};
      margintop: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.myS === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceS};
      margintop: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.myM === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceM};
      margintop: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.myL === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceL};
      margintop: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.myXL === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceXL};
      margintop: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.myXXL === true &&
    css`
      marginbottom: ${designTokens[props.theme].spaces.spaceXXL};
      margintop: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const marginStylesLeft = (props) => css`
  ${(props) =>
    props.mlNone === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.mlXS === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.mlS === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.mlM === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.mlL === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.mlXL === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.mlXXL === true &&
    css`
      marginleft: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const marginStylesRight = (props) => css`
  ${(props) =>
    props.mrNone === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.mrXS === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.mrS === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.mrM === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.mrL === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.mrXL === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.mrXXL === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const marginStylesHorizontal = (props) => css`
  ${(props) =>
    props.mxNone === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.noSpace};
      marginleft: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.mxXS === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceXS};
      marginleft: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.mxS === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceS};
      marginleft: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.mxM === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceM};
      marginleft: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.mxL === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceL};
      marginleft: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.mxXL === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceXL};
      marginleft: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.mxXXL === true &&
    css`
      marginright: ${designTokens[props.theme].spaces.spaceXXL};
      marginleft: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const paddingStylesTop = (props) => css`
  ${(props) =>
    props.ptNone === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.ptXS === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.ptS === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.ptM === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.ptL === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.ptXL === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.ptXXL === true &&
    css`
      paddingtop: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const paddingStylesBottom = (props) => css`
  ${(props) =>
    props.pbNone === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.pbXS === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.pbS === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.pbM === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.pbL === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.pbXL === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.pbXXL === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const paddingStylesVertical = (props) => css`
  ${(props) =>
    props.pyNone === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.noSpace};
      paddingtop: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.pyXS === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceXS};
      paddingtop: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.pyS === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceS};
      paddingtop: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.pyM === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceM};
      paddingtop: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.pyL === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceL};
      paddingtop: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.pyXL === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceXL};
      paddingtop: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.pyXXL === true &&
    css`
      paddingbottom: ${designTokens[props.theme].spaces.spaceXXL};
      paddingtop: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const paddingStylesLeft = (props) => css`
  ${(props) =>
    props.plNone === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.plXS === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.plS === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.plM === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.plL === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.plXL === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.plXXL === true &&
    css`
      paddingleft: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const paddingStylesRight = (props) => css`
  ${(props) =>
    props.prNone === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.prXS === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.prS === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.prM === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.prL === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.prXL === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.prXXL === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const paddingStylesHorizontal = (props) => css`
  ${(props) =>
    props.pxNone === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.noSpace};
      paddingleft: ${designTokens[props.theme].spaces.noSpace};
    `}
  ${(props) =>
    props.pxXS === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceXS};
      paddingleft: ${designTokens[props.theme].spaces.spaceXS};
    `}
  ${(props) =>
    props.pxS === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceS};
      paddingleft: ${designTokens[props.theme].spaces.spaceS};
    `}
  ${(props) =>
    props.pxM === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceM};
      paddingleft: ${designTokens[props.theme].spaces.spaceM};
    `}
  ${(props) =>
    props.pxL === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceL};
      paddingleft: ${designTokens[props.theme].spaces.spaceL};
    `}
  ${(props) =>
    props.pxXL === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceXL};
      paddingleft: ${designTokens[props.theme].spaces.spaceXL};
    `}
  ${(props) =>
    props.pxXXL === true &&
    css`
      paddingright: ${designTokens[props.theme].spaces.spaceXXL};
      paddingleft: ${designTokens[props.theme].spaces.spaceXXL};
    `}
`;

export const spacing = (props) => css`
  ${(props) => marginStylesTop(props)}
  ${(props) => marginStylesBottom(props)}
  ${(props) => marginStylesVertical(props)}
  ${(props) => marginStylesLeft(props)}  
  ${(props) => marginStylesRight(props)}
  ${(props) => marginStylesHorizontal(props)}
  ${(props) => paddingStylesTop(props)}
  ${(props) => paddingStylesBottom(props)}
  ${(props) => paddingStylesVertical(props)}
  ${(props) => paddingStylesLeft(props)}
  ${(props) => paddingStylesRight(props)}
  ${(props) => paddingStylesHorizontal(props)}
`;
