import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styles = (props) => css`
  align-self: flex-start;
  background-color: ${(props) =>
    props.colorscheme && props.colorscheme === "Dark"
      ? designTokens[props.theme].colors.secondary.s800
      : designTokens[props.theme].colors.neutral.n0};
  flex-direction: column;
  align-items: center;
  display: flex;
  flex: 1;
  border-radius: ${designTokens[props.theme].sizes.cardBorderRadius};
  padding: ${designTokens[props.theme].sizes.cardPaddingHorizontal}
    ${designTokens[props.theme].sizes.cardPaddingVertical};
  font-size: 14px;
  ${(props) =>
    props.colorscheme &&
    props.colorscheme === "Dark" &&
    css`
      color: ${designTokens[props.theme].colors.neutral.n50};
    `}
  ${(props) => spacing(props)}
`;
