import React from "react";

const Gift = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.25 13V22H7.25C5.45507 22 4 20.5449 4 18.75V13H11.25ZM20 13V18.75C20 20.5449 18.5449 22 16.75 22H12.75V13H20ZM14.5 2C16.2949 2 17.75 3.45507 17.75 5.25C17.75 5.89498 17.5621 6.49607 17.2381 7.00154L19.75 7C20.4404 7 21 7.46637 21 8.04167V10.9583C21 11.5336 20.4404 12 19.75 12L12.75 11.999V7H11.25V11.999L4.25 12C3.55964 12 3 11.5336 3 10.9583V8.04167C3 7.46637 3.55964 7 4.25 7L6.7619 7.00154C6.43788 6.49607 6.25 5.89498 6.25 5.25C6.25 3.45507 7.70507 2 9.5 2C10.5055 2 11.4044 2.45666 12.0006 3.17391C12.5956 2.45666 13.4945 2 14.5 2ZM9.5 3.5C8.5335 3.5 7.75 4.2835 7.75 5.25C7.75 6.16817 8.45711 6.92119 9.35647 6.9942L9.5 7H11.25V5.25L11.2442 5.10647C11.1712 4.20711 10.4182 3.5 9.5 3.5ZM14.5 3.5C13.5335 3.5 12.75 4.2835 12.75 5.25V7H14.5C15.4665 7 16.25 6.2165 16.25 5.25C16.25 4.2835 15.4665 3.5 14.5 3.5Z"
        fill={color || "black"}
      />
    </svg>
  );
};
export default Gift;
