import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styleText = (props) => css`
  color: ${designTokens[props.theme].colors.neutral.n300};
  font-family: "${designTokens[props.theme].typography.desktop.caption
    .fontFamily}";
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  padding-vertical: ${designTokens[props.theme].spaces.sSpaceSm};
  ${(props) => spacing(props)}
`;
