import React from "react";

const Exit = (props) => {
  const { color } = props;
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.50215 8.50002C7.05562 8.50002 7.5043 8.9487 7.5043 9.50217C7.5043 10.0556 7.05562 10.5043 6.50215 10.5043C5.94868 10.5043 5.5 10.0556 5.5 9.50217C5.5 8.9487 5.94868 8.50002 6.50215 8.50002ZM10 1.35418V7.5L10.0005 8.00496L17.442 8.004L15.7196 6.28026C15.4534 6.01395 15.4292 5.59728 15.6471 5.3037L15.7198 5.2196C15.9861 4.95338 16.4027 4.92924 16.6963 5.14715L16.7804 5.21978L19.777 8.21742C20.043 8.48349 20.0674 8.89969 19.85 9.19327L19.7775 9.27739L16.7809 12.2808C16.4884 12.5741 16.0135 12.5746 15.7203 12.282C15.4537 12.0161 15.429 11.5994 15.6465 11.3056L15.7191 11.2214L17.432 9.504L10.0005 9.50496L10 16.25C10 16.7164 9.57884 17.0697 9.11955 16.9886L0.619552 15.4873C0.26121 15.424 0 15.1126 0 14.7487V2.75002C0 2.38271 0.266014 2.06945 0.628466 2.00993L9.12847 0.614097C9.58508 0.539114 10 0.891454 10 1.35418ZM8.5 2.23739L1.5 3.3869V14.1196L8.5 15.3559V2.23739ZM11 15.5013L11.7652 15.5015L11.867 15.4946C12.2335 15.4448 12.5158 15.1304 12.5152 14.7502L12.508 10.5H11V15.5013ZM11.002 7.00002L11 5.72536V2.00001L11.7453 2.00002C12.1245 2.00002 12.4381 2.28154 12.4883 2.64713L12.4953 2.74878L12.502 7.00002H11.002Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default Exit;
