import { default as styledWeb } from "styled-components";
import {
  styleButton,
  styleButtonText,
  styleContainer,
} from "./Button.styles.base";

export const ButtonWeb = styledWeb.div`
  ${(props) => styleButton(props)}
`;
export const ButtonTextWeb = styledWeb.div`
  ${(props) => styleButtonText(props)}
`;
export const ContainerWeb = styledWeb.div`
  ${(props) => styleContainer(props)}
`;
