// stories/MyButton.tsx
import React from "react";
import PropTypes from "prop-types";
import ArrowRightIcon from "../../icons/ArrowRightIcon.web";
import FilterIcon from "../../icons/FilterIcon.web";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon.web";
import CheckIcon from "../../icons/CheckIcon.web";
import ChevronDownIcon from "../../icons/ChevronDownIcon.web";
import CompanyIcon from "../../icons/CompanyIcon.web";
import CopyIcon from "../../icons/CopyIcon.web";
import CloseIcon from "../../icons/CloseIcon.web";
import DocIcon from "../../icons/DocIcon.web";
import DotsIcon from "../../icons/DotsIcon.web";
import ExitIcon from "../../icons/ExitIcon.web";
import GiftIcon from "../../icons/GiftIcon.web";
import KeyIcon from "../../icons/KeyIcon.web";
import PdfIcon from "../../icons/PdfIcon.web";
import PasswordIcon from "../../icons/PasswordIcon.web";
import ReportIcon from "../../icons/ReportIcon.web";
import UserIcon from "../../icons/UserIcon.web";
import SearchIcon from "../../icons/SearchIcon.web";
import UploadIcon from "../../icons/UploadIcon.web";
import ViewIcon from "../../icons/ViewIcon.web";
import IconsList from "../../icons/index.js";

const Icon = ({ icon, color, props }) => {
  const getIcon = (icon, color) => {
    switch (icon) {
      case "Arrow Right":
        return <ArrowRightIcon color={color} />;
      case "Arrow Left":
        return <ArrowLeftIcon color={color} />;
      case "Check":
        return <CheckIcon color={color} />;
      case "Company":
        return <CompanyIcon color={color} />;
      case "Copy":
        return <CopyIcon color={color} />;
      case "Chevron Down":
        return <ChevronDownIcon color={color} />;
      case "Close":
        return <CloseIcon color={color} />;
      case "Doc":
        return <DocIcon color={color} />;
      case "Dots":
        return <DotsIcon color={color} />;
      case "Exit":
        return <ExitIcon color={color} />;
      case "Gift":
        return <GiftIcon color={color} />;
      case "Filter":
        return <FilterIcon color={color} />;
      case "Key":
        return <KeyIcon color={color} />;
      case "Pdf":
        return <PdfIcon color={color} />;
      case "Password":
        return <PasswordIcon color={color} />;
      case "Search":
        return <SearchIcon color={color} />;
      case "Upload":
        return <UploadIcon color={color} />;
      case "User":
        return <UserIcon color={color} />;
      case "Report":
        return <ReportIcon color={color} />;
      case "View":
        return <ViewIcon color={color} />;
      default:
        null;
    }
  };
  return <div {...props}>{getIcon(icon, color)}</div>;
};

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOf([...IconsList]),
  style: PropTypes.object,
};

export default Icon;
