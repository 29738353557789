import styled, { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styleButton = (props) => css`
  background-color: ${designTokens[props.theme].colors.primary.p600};
  padding-vertical: ${designTokens[props.theme].sizes
    .buttonPaddingVerticalMedium};
  border-radius: 0;
  align-self: flex-start;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.isBig &&
    props.isBig === true &&
    css`
      padding-vertical: ${designTokens[props.theme].sizes
        .buttonPaddingVertical};
    `}
  ${(props) =>
    props.rounded &&
    props.rounded === true &&
    css`
      border-radius: ${designTokens[props.theme].sizes.borderRadius};
    `}
  ${(props) =>
    props.flex &&
    props.flex === true &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.disabled &&
    props.disabled === true &&
    css`
      background-color: ${designTokens[props.theme].colors.tertiary.t300};
    `}
  
  ${(props) =>
    props.showIcon &&
    props.showIcon === true &&
    css`
      padding-horizontal: ${designTokens[props.theme].sizes
        .buttonPaddingHorizontalMedium};
      ${(props) =>
        props.isBig &&
        props.isBig === true &&
        css`
          padding-horizontal: ${designTokens[props.theme].sizes
            .buttonPaddingHorizontal};
        `}
    `}
  ${(props) =>
    props.iconOnly &&
    props.iconOnly === true &&
    css`
      padding-vertical: ${designTokens[props.theme].sizes
        .buttonPaddingVertical};
      padding-horizontal: ${designTokens[props.theme].sizes
        .buttonPaddingVertical};
      padding-right: ${designTokens[props.theme].sizes.buttonPaddingVertical};
      justify-content: center;
      align-items: center;
    `}
  ${(props) => spacing(props)}
`;

export const styleButtonText = (props) => css`
  color: ${designTokens[props.theme].colors.neutral.n900};
  font-family: "${designTokens[props.theme].typography.desktop.caption
    .fontFamily}";
  font-size: 18px;
  margin-horizontal: ${designTokens[props.theme].sizes.buttonPaddingHorizontal};
  ${(props) =>
    props.iconRight &&
    props.iconRight === true &&
    css`
      margin-left: 0;
      margin-right: ${designTokens[props.theme].sizes.buttonPaddingHorizontal};
    `}
  ${(props) =>
    props.iconLeft &&
    props.iconLeft === true &&
    css`
      margin-right: 0;
      margin-left: ${designTokens[props.theme].sizes.buttonPaddingHorizontal};
    `};
`;

export const styleContainer = (props) => css`
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
