import React, { useEffect, useState } from "react";
import { ButtonWeb, ButtonTextWeb } from "./Switch.styles.web.js";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const Switch = ({ selected, onChange, style, value, ...props }) => {
  const theme = getTheme();
  const [selectedButton, setSelectedButton] = useState(selected);

  useEffect(() => {
    setSelectedButton(selected);
  }, [selected]);

  const handleOnPress = () => {
    setSelectedButton(!selectedButton);
    if (onChange && typeof onChange === "function") onChange(value);
  };
  return (
    <ButtonWeb
      selected={selectedButton}
      style={style}
      onPress={handleOnPress}
      theme={theme}
      activeOpacity={0.8}
      {...props}>
      <ButtonTextWeb theme={theme} selected={selectedButton} />
    </ButtonWeb>
  );
};

Switch.propTypes = {
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.any,
};

export default Switch;
