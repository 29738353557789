// import * as Device from "expo-device";

// const isMobile = Device.osInternalBuildId;

export const setTheme = (theme) => {
  /*if (!isMobile && typeof window !== "undefined") {
    localStorage.setItem("ContbankDS_Theme", theme);
  }*/
};

export const getTheme = () => {
  /*if (
    !isMobile &&
    typeof window !== "undefined" &&
    localStorage.getItem("ContbankDS_Theme")
  ) {
    return localStorage.getItem("ContbankDS_Theme");
  } else {
    return require("../../../package.json").theme || "contbank";
  }*/
  return "contbank";
};
