const IconsList = [
  "Arrow Right",
  "Arrow Left",
  "Check",
  "Chevron Down",
  "Close",
  "Company",
  "Copy",
  "Doc",
  "Dots",
  "Exit",
  "Gift",
  "Filter",
  "Key",
  "Password",
  "Pdf",
  "Report",
  "Search",
  "Upload",
  "User",
  "View",
];

export default IconsList;
