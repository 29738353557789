import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styleCard = (props) => css`
  flex-direction: row;
  ${(props) =>
    props.dark &&
    props.dark === true &&
    css`
      color: ${designTokens[props.theme].colors.neutral.n0};
    `}
  ${(props) => spacing(props)}
`;

export const styleCardColumn = () => css`
  flex-direction: column;
  justify-content: center;
`;

export const styleIconBadge = (props) => css`
  border-radius: ${designTokens[props.theme].sizes.borderRadius};
  width: 48px;
  height: 48px;
  background-color: ${designTokens[props.theme].colors.primary.p600};
  justify-content: center;
  align-items: center;
  margin-right: 8;
  ${(props) =>
    props.medium &&
    props.medium === true &&
    css`
      width: 42;
      height: 42;
    `}
  ${(props) =>
    props.dark &&
    props.dark === true &&
    css`
      background-color: ${designTokens[props.theme].colors.secondary.s800};
    `}
`;

export const styleText = (props) => css`
  ${(props) =>
    props.dark &&
    props.dark === true &&
    css`
      color: ${designTokens[props.theme].colors.neutral.n0};
    `}
`;
