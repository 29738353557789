import React from "react";

const ArrowRight = (props) => {
  const { color } = props;
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.6351 0.301084L19.7846 8.53298C19.9226 8.67319 20 8.86286 20 9.06057C20 9.25827 19.9225 9.44794 19.7846 9.58816L11.6351 17.8201C11.4934 17.9427 11.3111 18.0067 11.1247 17.9994C10.9382 17.9922 10.7613 17.9141 10.6294 17.7808C10.4974 17.6475 10.4201 17.4689 10.4129 17.2805C10.4057 17.0922 10.4692 16.908 10.5905 16.7649L17.4731 9.80892L0.740857 9.80892C0.544369 9.80892 0.355929 9.73008 0.216992 9.58973C0.0780548 9.44939 3.72959e-07 9.25904 3.81428e-07 9.06057C3.89897e-07 8.86209 0.0780548 8.67174 0.216992 8.5314C0.355929 8.39106 0.544369 8.31221 0.740857 8.31221L17.4731 8.31221L10.5905 1.35626C10.4526 1.21605 10.3751 1.02638 10.3751 0.828672C10.3751 0.630968 10.4526 0.441297 10.5905 0.301084C10.7293 0.161701 10.9171 0.0834671 11.1128 0.0834671C11.3086 0.0834671 11.4963 0.161701 11.6351 0.301084Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default ArrowRight;
