import React from "react";

const Key = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M18.2493 7C18.2493 7.69036 17.6896 8.25 16.9993 8.25C16.3089 8.25 15.7493 7.69036 15.7493 7C15.7493 6.30964 16.3089 5.75 16.9993 5.75C17.6896 5.75 18.2493 6.30964 18.2493 7ZM15.4992 2.04999C11.885 2.04999 8.94922 4.98577 8.94922 8.59999C8.94922 8.9872 8.99939 9.36035 9.05968 9.704C9.07749 9.80547 9.04493 9.89362 8.99046 9.94809L2.75467 16.1839C2.23895 16.6996 1.94922 17.3991 1.94922 18.1284V20.3C1.94922 21.2665 2.73272 22.05 3.69922 22.05H6.19922C7.16572 22.05 7.94922 21.2665 7.94922 20.3V19.05H9.69922C10.3896 19.05 10.9492 18.4903 10.9492 17.8V16.05H12.6992C13.3741 16.05 13.9241 15.5151 13.9484 14.8462C14.4451 14.974 14.9689 15.05 15.4992 15.05C19.1134 15.05 22.0492 12.1142 22.0492 8.49999C22.0492 4.86884 19.0963 2.04999 15.4992 2.04999ZM10.4492 8.59999C10.4492 5.8142 12.7134 3.54999 15.4992 3.54999C18.3021 3.54999 20.5492 5.73114 20.5492 8.49999C20.5492 11.2858 18.285 13.55 15.4992 13.55C14.8199 13.55 14.1206 13.3789 13.4947 13.1106C13.2629 13.0113 12.9968 13.0351 12.7864 13.1739C12.5759 13.3126 12.4492 13.5479 12.4492 13.8V14.55H10.6992C10.0089 14.55 9.44922 15.1096 9.44922 15.8V17.55H7.69922C7.00886 17.55 6.44922 18.1096 6.44922 18.8V20.3C6.44922 20.4381 6.33729 20.55 6.19922 20.55H3.69922C3.56115 20.55 3.44922 20.4381 3.44922 20.3V18.1284C3.44922 17.7969 3.58091 17.479 3.81534 17.2445L10.0511 11.0087C10.4695 10.5904 10.6349 10.002 10.5371 9.44479C10.4834 9.13883 10.4492 8.86239 10.4492 8.59999Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default Key;
