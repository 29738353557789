import { default as styledWeb } from "styled-components";
import { styleBadge, styleBadgeText } from "./Badge.styles.base";

export const BadgeWeb = styledWeb.div`
  ${(props) => styleBadge(props)}
`;

export const BadgeTextWeb = styledWeb.div`
  ${(props) => styleBadgeText(props)}
`;
