import styled, { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styleBadge = (props) => css`
  padding-vertical: ${designTokens[props.theme].sizes.badgePaddingVertical};
  background-color: ${designTokens[props.theme].colors.primary.p600};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${designTokens[props.theme].sizes.borderRadius};
  padding-horizontal: ${designTokens[props.theme].sizes.badgePaddingHorizontal};
  ${(props) =>
    props.warning &&
    props.warning === true &&
    css`
      background-color: ${designTokens[props.theme].colors.warnings.a300};
    `}
  ${(props) =>
    props.light &&
    props.light === true &&
    css`
      background-color: ${designTokens[props.theme].colors.neutral.n100};
    `}
  ${(props) => spacing(props)}
`;

export const styleBadgeText = (props) => css`
  color: ${designTokens[props.theme].colors.neutral.n900};
  font-family: "${designTokens[props.theme].typography.desktop.caption
    .fontFamily}";
  font-size: 14px;
  ${(props) =>
    props.warning &&
    props.warning === true &&
    css`
      color: ${designTokens[props.theme].colors.neutral.n0};
    `}
  ${(props) =>
    props.light &&
    props.light === true &&
    css`
      color: ${designTokens[props.theme].colors.neutral.n900};
    `}
`;
