import React from "react";

const Upload = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.25 3.49561H18.7477C19.1619 3.49561 19.4977 3.15982 19.4977 2.74561C19.4977 2.36591 19.2155 2.05211 18.8494 2.00245L18.7477 1.99561H5.25C4.83579 1.99561 4.5 2.33139 4.5 2.74561C4.5 3.1253 4.78215 3.4391 5.14823 3.48876L5.25 3.49561ZM11.8834 21.9933L12 22C12.5128 22 12.9355 21.614 12.9933 21.1166L13 21V8.41001L16.2941 11.7028C16.6546 12.0632 17.2218 12.0909 17.6141 11.7858L17.7083 11.7026C18.0687 11.3421 18.0964 10.7749 17.7913 10.3826L17.7081 10.2884L12.7105 5.29181C12.3502 4.93157 11.7833 4.9037 11.391 5.20832L11.2968 5.2914L6.29338 10.288C5.90259 10.6783 5.90215 11.3114 6.29241 11.7022C6.65265 12.063 7.21986 12.0911 7.61236 11.7863L7.70662 11.7032L11 8.41501V21C11 21.5128 11.386 21.9355 11.8834 21.9933Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default Upload;
