import React from "react";

const Dots = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="3" cy="12" r="2" fill={color || "black"} />
      <circle cx="12" cy="12" r="2" fill={color || "black"} />
      <circle cx="21" cy="12" r="2" fill={color || "black"} />
    </svg>
  );
};
export default Dots;
