import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styles = (props) => css`
  align-self: flex-start;
  flex-direction: row;
  padding-vertical: ${designTokens[props.theme].spaces.sSpaceXxs};
  ${(props) => spacing(props)}
`;

export const stylesIcon = (props) => css`
  background-color: ${designTokens[props.theme].colors.neutral.n100};
  border-radius: 2px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  justify-content: center;
  align-self: center;
`;

export const stylesText = (props) => css`
  color: ${designTokens[props.theme].colors.neutral.n900};
  font-size: 16px;
  line-height: 22px;
  font-family: "Manrope";
  margin-horizontal: ${designTokens[props.theme].sizes
    .buttonPaddingHorizontalMedium};
`;

export const stylesIconMark = (props) => css`
  background-color: ${designTokens[props.theme].colors.neutral.n100};
  border-radius: 2px;
  width: 10px;
  align-self: center;
  height: 10px;
  ${(props) =>
    props.selected &&
    props.selected === true &&
    css`
      background-color: ${designTokens[props.theme].colors.neutral.n900};
    `};
`;
