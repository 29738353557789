import React from "react";

const FilterIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6 10H10C10.5523 10 11 10.4477 11 11C11 11.5128 10.614 11.9355 10.1166 11.9933L10 12H6C5.44772 12 5 11.5523 5 11C5 10.4872 5.38604 10.0645 5.88338 10.0067L6 10H10H6ZM4 5H12C12.5523 5 13 5.44772 13 6C13 6.51284 12.614 6.93551 12.1166 6.99327L12 7H4C3.44772 7 3 6.55228 3 6C3 5.48716 3.38604 5.06449 3.88338 5.00673L4 5H12H4ZM1 0H15C15.5523 0 16 0.447715 16 1C16 1.51284 15.614 1.93551 15.1166 1.99327L15 2H1C0.447715 2 0 1.55228 0 1C0 0.487164 0.38604 0.0644928 0.883379 0.0067277L1 0H15H1Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default FilterIcon;
