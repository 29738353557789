import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styles = (props) => css`
  color: ${designTokens[props.theme].colors.neutral.n900};
  ${(props) =>
    props.type &&
    props.type === "H1" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.h1
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.h1.size};
      line-height: ${designTokens[props.theme].typography.desktop.h1
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "H2" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.h2
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.h2.size};
      line-height: ${designTokens[props.theme].typography.desktop.h2
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "H3" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.h3
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.h3.size};
      line-height: ${designTokens[props.theme].typography.desktop.h3
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "H4" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.h4
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.h4.size};
      line-height: ${designTokens[props.theme].typography.desktop.h4
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "H5" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.h5
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.h5.size};
      line-height: ${designTokens[props.theme].typography.desktop.h5
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "Paragraph Large" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.paragraphLarge
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.paragraphLarge
        .size};
      line-height: ${designTokens[props.theme].typography.desktop.paragraphLarge
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "Paragraph Medium" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop
        .paragraphMedium.fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.paragraphMedium
        .size};
      line-height: ${designTokens[props.theme].typography.desktop
        .paragraphMedium.lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "Paragraph Small" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.paragraphSmall
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.paragraphSmall
        .size};
      line-height: ${designTokens[props.theme].typography.desktop.paragraphSmall
        .lineHeight};
    `}
  ${(props) =>
    props.type &&
    props.type === "Caption" &&
    css`
      font-family: ${designTokens[props.theme].typography.desktop.caption
        .fontFamily};
      font-size: ${designTokens[props.theme].typography.desktop.caption.size};
      line-height: ${designTokens[props.theme].typography.desktop.caption
        .lineHeight};
    `}
    ${(props) => spacing(props)}
`;
