import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styles = (props) => css`
  flex: 1;
  justify-content: center;
  flex-direction: column;
  border-radius: ${designTokens[props.theme].sizes.borderRadius};
  ${(props) =>
    props.dark &&
    props.dark === true &&
    css`
      background-color: ${designTokens[props.theme].colors.secondary.s800};
    `}
  ${(props) => spacing(props)}
`;
