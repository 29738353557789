import React from "react";
import { FilterCategoryWeb } from "./FilterCategory.styles.web.js";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const FilterCategory = ({ text, ...props }) => {
  const theme = getTheme();
  return (
    <FilterCategoryWeb theme={theme} {...props}>
      {text}
    </FilterCategoryWeb>
  );
};

FilterCategory.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FilterCategory;
