import { css } from "styled-components";
import designTokens from "../../configs/design-tokens";
import { spacing } from "../../configs/spaces.styles.base";

export const styles = (props) => css`
  padding: 4px;
  border-radius: 0;
  align-self: flex-start;
  background-color: ${designTokens[props.theme].colors.neutral.n100};
  flex-direction: row;
  width: 80px;
  border-radius: ${designTokens[props.theme].sizes.borderRadiusMedium};
  justify-content: flex-start;
  ${(props) =>
    props.selected &&
    props.selected === true &&
    css`
      justify-content: flex-end;
      background-color: ${designTokens[props.theme].colors.primary.p600};
    `}
  ${(props) => spacing(props)}
`;

export const stylesText = (props) => css`
  color: ${designTokens[props.theme].colors.neutral.n900};
  width: 32px;
  height: 32px;
  background-color: ${designTokens[props.theme].colors.neutral.n50};
  border-radius: 32px;
  align-self: flex-start;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;
