import React from "react";

const Close = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2.34588 20.3459C1.98461 20.7071 1.98461 21.2929 2.34588 21.6541C2.70714 22.0154 3.29286 22.0154 3.65412 21.6541L2.34588 20.3459ZM21.6541 3.65413C22.0154 3.29286 22.0154 2.70714 21.6541 2.34588C21.2929 1.98461 20.7071 1.98461 20.3459 2.34588L21.6541 3.65413ZM3.65412 21.6541L21.6541 3.65413L20.3459 2.34588L2.34588 20.3459L3.65412 21.6541Z"
        fill={color || "black"}
      />
      <path
        d="M20.3459 21.6541C20.7071 22.0154 21.2929 22.0154 21.6541 21.6541C22.0154 21.2929 22.0154 20.7071 21.6541 20.3459L20.3459 21.6541ZM3.65412 2.34588C3.29286 1.98461 2.70714 1.98461 2.34588 2.34588C1.98461 2.70714 1.98461 3.29286 2.34588 3.65413L3.65412 2.34588ZM21.6541 20.3459L3.65412 2.34588L2.34588 3.65413L20.3459 21.6541L21.6541 20.3459Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default Close;
