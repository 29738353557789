import React from "react";

const ArrowLeft = (props) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.3649 3.30108L2.21544 11.533C2.07745 11.6732 2 11.8629 2 12.0606C2 12.2583 2.07745 12.4479 2.21544 12.5882L10.3649 20.8201C10.5066 20.9427 10.6889 21.0067 10.8753 20.9994C11.0618 20.9922 11.2387 20.9141 11.3706 20.7808C11.5026 20.6475 11.5799 20.4689 11.5871 20.2805C11.5943 20.0922 11.5308 19.908 11.4095 19.7649L4.52691 12.8089L21.2591 12.8089C21.4556 12.8089 21.6441 12.7301 21.783 12.5897C21.9219 12.4494 22 12.259 22 12.0606C22 11.8621 21.9219 11.6717 21.783 11.5314C21.6441 11.3911 21.4556 11.3122 21.2591 11.3122L4.52691 11.3122L11.4095 4.35626C11.5474 4.21605 11.6249 4.02638 11.6249 3.82867C11.6249 3.63097 11.5474 3.4413 11.4095 3.30108C11.2707 3.1617 11.0829 3.08347 10.8872 3.08347C10.6914 3.08347 10.5037 3.1617 10.3649 3.30108Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default ArrowLeft;
