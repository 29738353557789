import { default as styledWeb } from "styled-components";
import {
  styleCard,
  styleCardColumn,
  styleIconBadge,
  styleText,
} from "./IconTextBadge.styles.base";

export const CardWeb = styledWeb.div`
  ${(props) => styleCard(props)}
`;

export const ColumnWeb = styledWeb.div`
  ${(props) => styleCardColumn(props)}
`;

export const IconBadgeWeb = styledWeb.div`
  ${(props) => styleIconBadge(props)}
`;

export const TextWeb = styledWeb.div`
  ${(props) => styleText(props)}
`;
