import React, { useEffect, useState } from "react";
import {
  ButtonWeb,
  ButtonIconWeb,
  ButtonIconMarkWeb,
  ButtonTextWeb,
} from "./FilterOption.styles.web.js";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const FilterOption = ({
  text,
  value,
  selected,
  onChange,
  colorscheme,
  size,
  style,
  ...props
}) => {
  const theme = getTheme();
  const [selectedButton, setSelectedButton] = useState(selected);
  const isDark = colorscheme === "Dark";
  const isBig = size === "Big";

  useEffect(() => {
    setSelectedButton(selected);
  }, [selected]);

  const handleOnPress = () => {
    setSelectedButton(!selectedButton);
    if (onChange && typeof onChange === "function") onChange(value);
  };
  return (
    <ButtonWeb
      dark={isDark}
      big={isBig}
      selected={selectedButton}
      style={style}
      onPress={handleOnPress}
      activeOpacity={0.8}
      {...props}>
      <ButtonIconWeb>
        <ButtonIconMarkWeb selected={selectedButton} />
      </ButtonIconWeb>
      <ButtonTextWeb dark={isDark} big={isBig} selected={selectedButton}>
        {text}
      </ButtonTextWeb>
    </ButtonWeb>
  );
};

FilterOption.propTypes = {
  text: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  style: PropTypes.object,
  colorscheme: PropTypes.oneOf(["Default", "Dark"]),
  size: PropTypes.oneOf(["Medium", "Big"]),
};

export default FilterOption;
