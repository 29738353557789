import React from "react";
import { ButtonWeb, ButtonTextWeb, ContainerWeb } from "./Button.styles.web.js";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon.component.react";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const Button = ({
  children,
  onPress,
  disabled,
  rounded,
  flex,
  showIcon,
  iconPosition,
  style,
  size,
}) => {
  const theme = getTheme();
  const iconOnly = !children;
  const iconRight = !showIcon && showIcon && iconPosition !== "Start";
  const iconLeft = !!showIcon && showIcon && iconPosition === "Start";
  const isBig = size && size === "Big";

  return (
    <ContainerWeb>
      <ButtonWeb
        theme={theme}
        disabled={!!disabled}
        rounded={!!rounded}
        flex={!!flex}
        showIcon={!!showIcon}
        iconOnly={!!iconOnly}
        isBig={!!isBig}
        style={[style]}
        onPress={onPress}
        activeOpacity={0.8}>
        {iconPosition === "Start" && <Icon icon={showIcon} />}
        {children && (
          <ButtonTextWeb
            theme={theme}
            iconRight={!!iconRight}
            iconLeft={!!iconLeft}>
            {children}
          </ButtonTextWeb>
        )}
        {iconPosition !== "Start" && <Icon icon={showIcon} />}
      </ButtonWeb>
    </ContainerWeb>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  flex: PropTypes.bool,
  showIcon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["Start", "End"]),
  onPress: PropTypes.func,
  style: PropTypes.object,
  size: PropTypes.oneOf(["Medium", "Big"]),
};

export default Button;
