import React, { useEffect, useState } from "react";
import ActiveInactiveButton from "../ActiveInactiveButton/ActiveInactiveButton.component.react";
import PropTypes from "prop-types";
import { ContainerWeb } from "./RadioGroup.styles.web.js";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component";

const RadioGroup = ({
  options,
  value,
  onChange,
  colorscheme,
  size,
  style,
  ...props
}) => {
  const theme = getTheme();
  const [selectedValue, setSelectedValue] = useState(value || options[0].value);
  const isDark = colorscheme === "Dark";

  const onChangeHandle = (newValue) => {
    setSelectedValue(newValue);
    if (onChange && typeof onChange === "function") onChange(value);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <ContainerWeb dark={isDark} style={style} theme={theme} {...props}>
      {options.map((option) => (
        <ActiveInactiveButton
          text={option.text}
          selected={option.value === selectedValue}
          onChange={() => onChangeHandle(option.value)}
          key={option.value}
          colorscheme={colorscheme}
          size={size}
          value={option.value}
        />
      ))}
    </ContainerWeb>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  colorscheme: PropTypes.oneOf(["Default", "Dark"]),
  size: PropTypes.oneOf(["Medium", "Big"]),
  value: PropTypes.any,
  style: PropTypes.object,
};

export default RadioGroup;
