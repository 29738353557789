import React from "react";
import { CardWeb } from "./Card.styles.web.js";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const Card = ({ children, colorscheme, ...props }) => {
  const theme = getTheme();

  return (
    <CardWeb theme={theme} colorscheme={colorscheme} {...props}>
      {children && <>{children}</>}
    </CardWeb>
  );
};

Card.propTypes = {
  children: PropTypes.string.isRequired,
  colorscheme: PropTypes.oneOf(["Default", "Dark"]),
};

export default Card;
