import { default as styledWeb } from "styled-components";
import {
  styles,
  stylesIcon,
  stylesIconMark,
  stylesText,
} from "./FilterOption.styles.base";

export const ButtonWeb = styledWeb.div`
  ${(props) => styles(props)}
`;

export const ButtonIconWeb = styledWeb.div`
  ${(props) => stylesIcon(props)}
`;

export const ButtonIconMarkWeb = styledWeb.div`
  ${(props) => stylesIconMark(props)}
`;

export const ButtonTextWeb = styledWeb.div`
  ${(props) => stylesText(props)}
`;
