const DesignTokens = {
  contbank: {
    colors: {
      primary: {
        p200: "#FCF277",
        p600: "#FFE03D",
        p800: "#F0C100",
      },
      secondary: {
        s100: "#E8F3FF",
        s200: "#DEE9F6",
        s400: "#AAB4C1",
        s600: "#626C77",
        s700: "#364E63",
        s800: "#1B2537",
        s900: "#101A23",
      },
      tertiary: {
        t200: "#EBE6D7",
        t300: "#E7E1CF",
        t400: "#A29E91",
        t500: "#8D897E",
      },
      neutral: {
        n0: "#FFFFFF",
        n50: "#F7F6F2",
        n100: "#E0E1E2",
        n200: "#C6C8CB",
        n300: "#84898E",
        n400: "#6C7278",
        n500: "#474F56",
        n600: "#41484E",
        n700: "#32383D",
        n800: "#272B2F",
        n900: "#1E2124",
      },
      warnings: {
        a100: "#FCC8D1",
        a200: "#EB9198",
        a300: "#E06670",
        a400: "#8D897E",
      },
    },
    typography: {
      desktop: {
        h1: {
          fontFamily: "Manrope",
          size: "48px",
          lineHeight: "54px",
        },
        h2: {
          fontFamily: "Manrope",
          size: "40px",
          lineHeight: "46px",
        },
        h3: {
          fontFamily: "Manrope",
          size: "32px",
          lineHeight: "38px",
        },
        h4: {
          fontFamily: "Manrope",
          size: "24px",
          lineHeight: "30px",
        },
        h5: {
          fontFamily: "Manrope",
          size: "18px",
          lineHeight: "24px",
        },
        paragraphLarge: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "22px",
        },
        paragraphSmall: {
          fontFamily: "Manrope",
          size: "14px",
          lineHeight: "20px",
        },
        paragraphMedium: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "22px",
        },
        caption: {
          fontFamily: "Manrope",
          size: "12px",
          lineHeight: "18px",
        },
      },
      mobile: {
        h1: {
          fontFamily: "Manrope",
          size: "32px",
          lineHeight: "38px",
        },
        h2: {
          fontFamily: "Manrope",
          size: "24px",
          lineHeight: "30px",
        },
        h3: {
          fontFamily: "Manrope",
          size: "20px",
          lineHeight: "26px",
        },
        h4: {
          fontFamily: "Manrope",
          size: "18px",
          lineHeight: "24px",
        },
        h5: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "22px",
        },
        paragraphLarge: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "22px",
        },
        paragraphSmall: {
          fontFamily: "Manrope",
          size: "14px",
          lineHeight: "20px",
        },
        caption: {
          fontFamily: "Manrope",
          size: "12px",
          lineHeight: "18px",
        },
      },
    },
    sizes: {
      buttonPaddingVerticalMedium: "12px",
      buttonPaddingHorizontalMedium: "16px",
      buttonPaddingVertical: "24px",
      buttonPaddingHorizontal: "32px",
      borderRadiusMedium: "24px",
      borderRadius: "100px",
      badgePaddingVertical: "6px",
      badgePaddingHorizontal: "12px",
      cardPaddingHorizontal: "12px",
      cardPaddingVertical: "16px",
      cardBorderRadius: "8px",
    },
    spaces: {
      noSpace: "0px",
      spaceXS: "4px",
      spaceS: "8px",
      spaceM: "16px",
      spaceL: "24px",
      spaceXL: "32px",
      spaceXXL: "48px",
    },
  },
  kikai: {
    colors: {
      primary: {
        p200: "#ccff00",
        p600: "#ccff00",
        p800: "#ccff00",
      },
      secondary: {
        s100: "#E8F3FF",
        s200: "#DEE9F6",
        s400: "#AAB4C1",
        s600: "#626C77",
        s700: "#364E63",
        s800: "#1B2537",
        s900: "#101A23",
      },
      tertiary: {
        t200: "#EBE6D7",
        t300: "#E7E1CF",
        t400: "#A29E91",
        t500: "#8D897E",
      },
      neutral: {
        n0: "#FFFFFF",
        n50: "#ccff00",
        n100: "#E0E1E2",
        n200: "#C6C8CB",
        n300: "#FFE03D",
        n400: "#6C7278",
        n500: "#474F56",
        n600: "#41484E",
        n700: "#32383D",
        n800: "#272B2F",
        n900: "#ccff00",
      },
      warnings: {
        a100: "#FCC8D1",
        a200: "#EB9198",
        a300: "#E06670",
        a400: "#8D897E",
      },
    },
    typography: {
      desktop: {
        h1: {
          fontFamily: "Manrope",
          size: "48px",
          lineHeight: "56px",
        },
        h2: {
          fontFamily: "Manrope",
          size: "40px",
          lineHeight: "48px",
        },
        h3: {
          fontFamily: "Manrope",
          size: "32px",
          lineHeight: "40px",
        },
        h4: {
          fontFamily: "Manrope",
          size: "24px",
          lineHeight: "32px",
        },
        h5: {
          fontFamily: "Manrope",
          size: "18px",
          lineHeight: "24px",
        },
        paragraphLarge: {
          fontFamily: "Manrope",
          size: "18px",
          lineHeight: "32px",
        },
        paragraphSmall: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "24px",
        },
        paragraphMedium: {
          fontFamily: "Manrope",
          size: "14px",
          lineHeight: "20px",
        },
        caption: {
          fontFamily: "Manrope",
          size: "12px",
          lineHeight: "20px",
        },
      },
      mobile: {
        h1: {
          fontFamily: "Manrope",
          size: "32px",
          lineHeight: "40px",
        },
        h2: {
          fontFamily: "Manrope",
          size: "24px",
          lineHeight: "32px",
        },
        h3: {
          fontFamily: "Manrope",
          size: "20px",
          lineHeight: "32px",
        },
        h4: {
          fontFamily: "Manrope",
          size: "18px",
          lineHeight: "32px",
        },
        h5: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "24px",
        },
        paragraphLarge: {
          fontFamily: "Manrope",
          size: "16px",
          lineHeight: "24px",
        },
        paragraphSmall: {
          fontFamily: "Manrope",
          size: "14px",
          lineHeight: "20px",
        },
        caption: {
          fontFamily: "Manrope",
          size: "12px",
          lineHeight: "20px",
        },
      },
    },
    sizes: {
      buttonPaddingVerticalMedium: "12px",
      buttonPaddingHorizontalMedium: "16px",
      buttonPaddingVertical: "24px",
      buttonPaddingHorizontal: "32px",
      borderRadiusMedium: "24px",
      borderRadius: "100px",
      badgePaddingVertical: "6px",
      badgePaddingHorizontal: "12px",
      cardPaddingHorizontal: "12px",
      cardPaddingVertical: "16px",
      cardBorderRadius: "8px",
    },
    spaces: {
      noSpace: "0px",
      spaceXS: "4px",
      spaceS: "8px",
      spaceM: "16px",
      sSpaceL: "24px",
      spaceXL: "32px",
      spaceXXL: "48px",
    },
  },
  grid: {},
};

export default DesignTokens;
