import React from "react";
import PropTypes from "prop-types";
import { RowWeb } from "./Row.styles.web.js";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const Row = ({ children, size, align, ...props }) => {
  const theme = getTheme();
  return (
    <RowWeb theme={theme} size={size} contentAlign={align} {...props}>
      {children}
    </RowWeb>
  );
};

Row.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["x1", "x2", "x3", "x4", "x5", "x6"]),
  align: PropTypes.oneOf(["Start", "Center", "End"]),
};

export default Row;
