import React from "react";

const User = (props) => {
  const { color } = props;
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.7511 11.9957C14.9931 11.9957 16 13.0026 16 14.2446V14.82C16 15.7143 15.6804 16.5792 15.0989 17.2586C13.5294 19.0922 11.1422 19.9971 7.9967 19.9971C4.85074 19.9971 2.46466 19.0919 0.898308 17.2576C0.318528 16.5786 0 15.7151 0 14.8223V14.2446C0 13.0026 1.00689 11.9957 2.24895 11.9957H13.7511ZM13.7511 13.4957H2.24895C1.83534 13.4957 1.50005 13.831 1.50005 14.2446V14.8223C1.50005 15.358 1.69116 15.8761 2.03903 16.2835C3.29238 17.7512 5.25832 18.497 7.9967 18.497C10.7351 18.497 12.7027 17.7512 13.9593 16.2832C14.3082 15.8755 14.5 15.3566 14.5 14.82V14.2446C14.5 13.831 14.1647 13.4957 13.7511 13.4957ZM7.9967 0C10.7582 0 12.9969 2.23864 12.9969 5.00015C12.9969 7.76166 10.7582 10.0003 7.9967 10.0003C5.2352 10.0003 2.99655 7.76166 2.99655 5.00015C2.99655 2.23864 5.2352 0 7.9967 0ZM7.9967 1.50005C6.06365 1.50005 4.4966 3.0671 4.4966 5.00015C4.4966 6.93321 6.06365 8.50026 7.9967 8.50026C9.92976 8.50026 11.4968 6.93321 11.4968 5.00015C11.4968 3.0671 9.92976 1.50005 7.9967 1.50005Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default User;
