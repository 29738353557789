import React from "react";
import { TextWeb } from "./TextComponent.styles.web.js";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const TextComponent = ({ children, type, style, ...props }) => {
  const theme = getTheme();

  return (
    children && (
      <TextWeb type={type} style={style} theme={theme} {...props}>
        {children}
      </TextWeb>
    )
  );
};

TextComponent.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "Paragraph Large",
    "Paragraph Medium",
    "Paragraph Small",
    "Caption",
  ]),
  style: PropTypes.object,
};

export default TextComponent;
