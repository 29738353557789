import { css } from "styled-components";
import { spacing } from "../../configs/spaces.styles.base";

export const styles = (props) => css`
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-content: center;
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  ${(props) => {
    switch (props.contentAlign) {
      case "Center":
        return css`
          align-items: center;
        `;
      case "End":
        return css`
          align-items: flex-end;
        `;
      default:
        return css`
          align-items: flex-start;
        `;
    }
  }}
  ${(props) => {
    switch (props.size) {
      case "x2":
        return css`
          flex: 2 1 0%;
        `;
      case "x3":
        return css`
          flex: 3 1 0%;
        `;
      case "x4":
        return css`
          flex: 4 1 0%;
        `;
      case "x5":
        return css`
          flex: 5 1 0%;
        `;
      case "x6":
        return css`
          flex: 6 1 0%;
        `;
      default:
        return css`
          flex: 1 1 0%;
        `;
    }
  }}
  ${(props) => spacing(props)}
`;
