import React from "react";
import { BadgeWeb, BadgeTextWeb } from "./Badge.styles.web.js";
import PropTypes from "prop-types";
import { getTheme } from "../../services/ThemeProvider/ThemeService.component.js";

const Badge = ({ warning, light, children, style, ...props}) => {
  const theme = getTheme();
  return (
    <BadgeWeb theme={theme} warning={!!warning} light={!!light} style={[style]} {...props}>
      {children && (
        <BadgeTextWeb theme={theme} warning={!!warning} light={!!light}>
          {children}
        </BadgeTextWeb>
      )}
    </BadgeWeb>
  );
};

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  light: PropTypes.bool,
  style: PropTypes.object,
};

export default Badge;
